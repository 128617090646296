<template>
  <div>
    <my-header title="意见反馈" class="myheader">
      <template v-slot:right>
        <div class="header-slot" @click="goHistory">历史反馈</div>
      </template>
    </my-header>
    <div class="feed">
      <div class="content">
        <textarea
          class="area"
          rows="4"
          v-model="feedContent"
          placeholder="请留下您宝贵的意见或建议"
        />
        <p>{{ feedContent.length }}/200</p>
      </div>
      <div class="upImg">
        <van-uploader
          v-model="fileList"
          :multiple="false"
          :after-read="afterRead"
          @delete="preview"
          :max-count="5"
          :preview-options="{
            images: viewImgList,
          }"
        >
          <div class="upload">
            <van-icon name="plus" color="#979797" style="font-size: 25px" />
            <p>上传图片</p>
            <p>(最大5张)</p>
          </div>
        </van-uploader>
      </div>
      <van-button
        color="linear-gradient(90deg, #FB7A08, #FE6113)"
        class="button"
        @click="submit"
        >提交</van-button
      >
    </div>
  </div>
</template>
<script>
import { upload } from '@/api/upload'
import { addFeedBack } from '@/api/feedBack'
export default {
  data() {
    return {
      feedContent: '',
      fileList: [],
      viewImgList: [],
    }
  },
  watch: {
    feedContent(newVal, oldVal) {
      if (newVal.length > 200) {
        this.feedContent = oldVal
      }
    },
  },
  created() {},
  computed: {},
  methods: {
    afterRead(file) {
      let formData = new FormData()
      formData.append('file', file.file)
      upload(formData).then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.$toast.success('上传成功!')
          this.fileList[this.fileList.length - 1] = {
            url: res.result.url,
            fileId: res.result.id,
          }
          this.dealList()
          // if (res.result && res.result.constructor == Array) {
          //   this.fileList = res.result
          //   console.log(123);
          // }
        }
      })
    },
    dealList() {
      let arr = []
      for (const item of this.fileList) {
        console.log(item)
        arr.push(item.url)
      }
      this.viewImgList = arr
    },
    preview() {
      this.dealList()
    },
    submit() {
      for (let i = 0; i < this.fileList.length; i++) {
        this.fileList[i].itemOrder = i + 1
      }
      if (!this.feedContent) {
        this.$toast.fail('请输入反馈内容!')
        return
      }
      let obj = {
        feedCon: this.feedContent,
        feedbackFiles: this.fileList,
      }
      addFeedBack(obj).then((res) => {
        if (res.code == 200) {
          this.$toast.success('提交成功!')
          this.feedContent = ''
          this.fileList = []
          this.$router.push({
            path: '/my',
          })
        }
      })
    },
    goHistory() {
      this.$router.push({
        path: '/feedback/history',
      })
    },
  },
}
</script>
<style lang="less" scoped>
.myheader {
  position: relative;
  .header-slot {
    position: absolute;
    right: 64px;
    bottom: 47px;
    font-size: 48px;
    color: #8d8d8d;
  }
}
.feed {
  padding: 0 64px;
  .content {
    position: relative;
    margin-top: 64px;
    .area {
      width: 92%;
      height: 864px;
      border: 4px solid #b5b5b6;
      border-radius: 32px;
      padding: 44px;
      resize: none;
      font-size: 48px;
      font-weight: 400;
      color: #979797;
    }
    P {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 40px;
      color: #bfc0c0;
      bottom: 48px;
      right: 44px;
    }
  }
  .upImg {
    margin-top: 64px;
    .upload {
      width: 200px;
      height: 200px;
      border: 4px dashed #979797;
      text-align: center;
      p {
        font-size: 32px;
        color: #979797;
      }
    }
    ::v-deep .van-image {
      width: 200px;
      height: 200px;
    }
  }
  .button {
    width: 1156px;
    position: fixed;
    bottom: 120px;
    left: 64px;
    border-radius: 16px;
  }
}
</style>
